var Constants = {};

Constants.STATUS = {
  REG: 'REG',
  PRE: 'PRE',
  ACCL: 'ACCL',
  UCCL: 'UCCL',
  AABS: 'AABS',
  CALL: 'CALL',
  ABS: 'ABS',
  CCL: 'CCL',
  FIN: 'FIN',
};

Constants.STATUS_NAME = {
  REG: '登録済み',
  PRE: '事前呼出',
  ACCL: '取消(自動)',
  UCCL: '取消(お客様)',
  AABS: '不在(自動)',
  CALL: '呼出',
  ABS: '不在',
  CCL: '取消',
  FIN: '入店',
};

Constants.JA_DISPLAY_NAME = {
  COUNT1: '現在の待ち組数',
  COUNT2: '組',
  COUNT3: '約',
  COUNT4: '分',
  COUNT5: '※待ち時間は状況により、前後する可能性があります。ご了承下さいませ。',
  REQUEST1: '大人のお客様の人数(7歳以上)',
  REQUEST2: '6歳以下のお子様の人数',
  REQUEST3: 'カウンター or テーブル',
  REQUEST4: 'テーブル',
  REQUEST5: 'どちらでも',
  REQUEST6: '注意事項',
  REQUEST7: '※呼び出しから10分経過した場合、次のお客様を優先してご案内させていただきます。また、呼び出しから20分経過しますと、順番待ちは自動的にキャンセルとなります。',
  BOTTOM1: '申込む',
  BOTTOM2: '順番待ちをやめる',
  HEADER1: '順番待ち',
  HEADER2: '呼出し状況',
  HEADER3: '受付番号確認',
  RECEIPT1: 'あなたの受付番号は',
  COUNTER1: 'お呼出し中の受付番号',
  COUNTER2: 'お呼出し済みでご不在のお客様',
  CANCEL1: 'キャンセルしてもよろしいですか？',
  CANCEL2: 'キャンセルしました',
  CANCEL3: '受付番号',
  SUBMIT1: '受付番号を発行しました',
  SUBMIT2: '受付番号を発行してもよろしいですか？',
};

Constants.EN_DISPLAY_NAME = {
  COUNT1: 'Current queue count',
  COUNT2: 'Group',
  COUNT3: 'About',
  COUNT4: 'Minutes',
  COUNT5: '※Waiting time may vary depending on the situation. Thank you for your understanding.',
  REQUEST1: 'Number of adult customers(7 years and older)',
  REQUEST2: 'Number of children(6 years and younger)',
  REQUEST3: 'Counter or Table',
  REQUEST4: 'Table',
  REQUEST5: 'Either',
  REQUEST6: 'Notes',
  REQUEST7: '※If 10 minutes have passed since the call, we will prioritize the next customer. Additionally, if 20 minutes have passed since the call, your reservation will be automatically canceled.',
  BOTTOM1: 'Apply for',
  BOTTOM2: 'Stop waiting in line',
  HEADER1: 'Waiting in line',
  HEADER2: 'Call status',
  HEADER3: 'Reception number',
  RECEIPT1: 'Your reception number',
  COUNTER1: 'Calling Reception Number',
  COUNTER2: 'Customers who have been called and are not here',
  CANCEL1: 'Is it okay to cancel?',
  CANCEL2: 'Canceled.',
  CANCEL3: 'Reception number',
  SUBMIT1: 'The reception number has been issued.',
  SUBMIT2: 'Is it okay to issue the reception number?',
  SIGNAGE_TOP_1: 'Currently',
  SIGNAGE_TOP_2: 'groups waiting',
  SIGNAGE_CALL: 'Ticket numbers currently being called',
  SIGNAGE_ABS: 'Customers who have been called but were absent',
  SIGNAGE_BOTTOM_1: 'If you have one of the nombers above, ',
  SIGNAGE_BOTTOM_2: 'please inform a restaurant staff',
};

Constants.ZHHANS_DISPLAY_NAME = {
  COUNT1: '当前等待数',
  COUNT2: '组',
  COUNT3: '约',
  COUNT4: '分钟',
  COUNT5: '※等待时间可能会因情况而有所不同。感谢您的理解。',
  REQUEST1: '成人顾客人数(7岁及以上)',
  REQUEST2: '6岁以下儿童人数',
  REQUEST3: '柜台或桌子',
  REQUEST4: '桌子',
  REQUEST5: '都可以',
  REQUEST6: '注意事项',
  REQUEST7: '※如果呼叫后经过10分钟，我们将优先安排下一个顾客。此外，如果呼叫后经过20分钟，您的预约将自动取消。',
  BOTTOM1: '申请',
  BOTTOM2: '停止轮班',
  HEADER1: '轮班等候',
  HEADER2: '调用状态',
  HEADER3: '接收号码',
  RECEIPT1: '你的承诺',
  COUNTER1: '正在呼叫的挂号号码',
  COUNTER2: '已呼叫但不在的客户',
  CANCEL1: '可以取消吗？',
  CANCEL2: '已取消。',
  CANCEL3: '接待号码',
  SUBMIT1: '接待号码已发放。',
  SUBMIT2: '可以发放接待号码吗？',
  SIGNAGE_TOP_1: '目前有',
  SIGNAGE_TOP_2: '组在等待',
  SIGNAGE_CALL: '当前正在呼叫的号码',
  SIGNAGE_ABS: '已被呼叫但未到的顾客',
  SIGNAGE_BOTTOM_1: '如果您拥有上述号码之一，',
  SIGNAGE_BOTTOM_2: '请告知餐厅工作人员',
};

Constants.ZHHANT_DISPLAY_NAME = {
  COUNT1: '當前等待組數',
  COUNT2: '庫米',
  COUNT3: '約',
  COUNT4: '分鐘',
  COUNT5: '※等待時間可能會因情況而有所不同。感謝您的理解。',
  REQUEST1: '成人顧客人數(7歲及以上)',
  REQUEST2: '6歲以下兒童人數',
  REQUEST3: '櫃檯或桌子',
  REQUEST4: '桌子',
  REQUEST5: '都可以',
  REQUEST6: '注意事項',
  REQUEST7: '※如果呼叫後經過10分鐘，我們將優先安排下一位顧客。此外，如果呼叫後經過20分鐘，您的預約將自動取消。',
  BOTTOM1: '埋頭苦幹',
  BOTTOM2: '停止輪班',
  HEADER1: '順序',
  HEADER2: '調用狀態',
  HEADER3: '接收號碼',
  RECEIPT1: '你的掛號信',
  COUNTER1: '正在呼叫的掛號號碼',
  COUNTER2: '已呼叫但不在的客戶',
  CANCEL1: '可以取消嗎？',
  CANCEL2: '已取消。',
  CANCEL3: '接待號碼',
  SUBMIT1: '接待號碼已發放。',
  SUBMIT2: '可以發放接待號碼嗎？',
  SIGNAGE_TOP_1: '目前',
  SIGNAGE_TOP_2: '組等待中',
  SIGNAGE_CALL: '當前正在呼叫的號碼',
  SIGNAGE_ABS: '已被呼叫但未到場的顧客',
  SIGNAGE_BOTTOM_1: '如果您擁有上述號碼之一，',
  SIGNAGE_BOTTOM_2: '請告知餐廳工作人員',
};

Constants.KO_DISPLAY_NAME = {
  COUNT1: '현재 대기조수',
  COUNT2: '조',
  COUNT3: '약',
  COUNT4: '분',
  COUNT5: '※ 대기 시간은 상황에 따라 달라질 수 있습니다. 양해 부탁드립니다',
  REQUEST1: '성인 고객 수(7세 이상)',
  REQUEST2: '6세 이하 어린이 수',
  REQUEST3: '카운터 또는 테이블',
  REQUEST4: '테이블',
  REQUEST5: '어느 쪽이든',
  REQUEST6: '주의 사항',
  REQUEST7: '※호출 후 10분이 경과하면 다음 고객을 우선 안내해 드립니다. 또한, 호출 후 20분이 경과하면 대기 순서는 자동으로 취소됩니다。',
  BOTTOM1: '신청합니다',
  BOTTOM2: '순번 대기를 그만두다',
  HEADER1: '순번 대기',
  HEADER2: '호출 상황',
  HEADER3: '접수 번호',
  RECEIPT1: '당신의 접수 번호',
  COUNTER1: '호출 중인 접수 번호',
  COUNTER2: '호출이 되어 부재중이신 고객님',
  CANCEL1: '취소해도 괜찮습니까?',
  CANCEL2: '취소되었습니다.',
  CANCEL3: '접수 번호',
  SUBMIT1: '접수 번호가 발급되었습니다.',
  SUBMIT2: '접수 번호를 발급해도 괜찮습니까?',
  SIGNAGE_TOP_1: '현재',
  SIGNAGE_TOP_2: '팀 대기 중입니다',
  SIGNAGE_CALL: '현재 호출 중인 티켓 번호',
  SIGNAGE_ABS: '호출되었지만 부재중인 고객',
  SIGNAGE_BOTTOM_1: '위 번호 중 하나가 있다면,',
  SIGNAGE_BOTTOM_2: '레스토랑 직원에게 알려주세요',
};

Constants.PAPER_ORDER = 'PAPER_ORDER';

Constants.METHOD = {
};

Constants.METHOD_NAME = {
};

Constants.CLASS = {
};

Constants.CLASS_NAME = {
};

Constants.PAYMENT = {

};

Constants.PAYMENT_NAME = {
};

Constants.CHANNEL = {
};

Constants.HEADING = {
  ADULT_NAME: '大人のお客様の人数(7歳以上)',
  CHILD_NAME: '6歳以下のお子様の人数',
  COUNTER_NAME: 'カウンター or テーブル',
  NOTE_NAME: '注意事項',
  ADULT_SHORT: '大人',
  CHILD_SHORT: '6歳以下',
  COUNTER_SHORT: '席',
}

Constants.COUNTER = {
  COUNTER: '1',
  TABLE: '2',
};

Constants.COUNTER_NAME = {
  '1': 'どちらでも',
  '2': 'テーブル',
  COUNTER: 'どちらでも',
  TABLE: 'テーブル',
};

Constants.COLUMN = {
  ADULT: 'data1',
  CHILD: 'data2',
  COUNTER: 'data3',
};

Constants.SAIBAN_CLASS = {
  RECEIPT: 'RECEIPT',
};

Constants.RECEIPT_NUM = {
};

Constants.SHOP_ID = 1;

Constants.REQUEST = "request";
Constants.CANCEL = "cancel";

Constants.SETTING = {
  INTERVAL_ABS: "INTERVAL_ABS",
  INTERVAL_CCL: "INTERVAL_CCL",
  INTERVAL_BATCH: "INTERVAL_BATCH",
  CAL_DISP_DEFAULT_START: "CAL_DISP_DEFAULT_START",
  CAL_DISP_DEFAULT_END: "CAL_DISP_DEFAULT_END",
  CAL_LINE_DEFAULT_START: "CAL_LINE_DEFAULT_START",
  CAL_LINE_DEFAULT_END: "CAL_LINE_DEFAULT_END",
  OPEN_DISP_FLG: "OPEN_DISP_FLG",
  OPEN_LINE_FLG: "OPEN_LINE_FLG",
  OPEN_WAIT_FLG: "OPEN_WAIT_FLG",
  LIMIT_PRE: "LIMIT_PRE",
  OPERATE_DATE: "OPERATE_DATE",
}

Constants.OPEN = "1";
Constants.CLOSE = "2";

Constants.ACTIVE_BUTTONS = {
  'REG': [
    'PRE',
  ],
  'PRE': [
    'CALL',
  ],
  'ABS': [
    'FIN'
  ],
  'AABS': [
    'FIN'
  ],
  'CALL': [
    'HOLD',
    'FIN',
  ],
  'CCL': [],
  'UCCL': [],
  'ACCL': [],
  'FIN': [],
};

Constants.COLOR = {
  1: 'info',
  2: 'success',
  '1': 'info',
  '2': 'success',
  BUS: 'info',
  TRAIN: 'success',
};

Constants.MAX_CALL = 15;
Constants.MAX_ABS = 10;
Constants.MAX_PRE = 4;

export default Constants;