import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import util from 'util';
import sound from '../Sound/Notify';

const Signage = (props) => {
  const [wait_count, setWaitCount] = useState(null);
  const [call_data, setCallData] = useState(null);
  const [abs_data, setAbsData] = useState(null);
  const [highlight, setHighlight] = useState([]);
  const [count_order, setCountOrder] = useState('');
  const [language, setLanguage] = useState('en');
  var previos_call_data = null;

  const refreshOrderCount = async () => {
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/`)).data;
    // sound play
    if (data.call_data && previos_call_data) {
      var highlight_data = data.call_data.filter(data => !previos_call_data.includes(data.receipt_num)).map(d => d.receipt_num);
      setHighlight(highlight_data);
      if (highlight_data.length > 0) {
        sound.play();
      }
    }
    setWaitCount(data.wait_count);
    setCountOrder(data.wait_count !== null && data.wait_count.length > 0 ? data.wait_count[0].count_order : 0);

    // 呼出中(CALL)
    var check_blank = new Array(constClass.MAX_CALL - (data.call_data ? Math.min(data.call_data.length, constClass.MAX_CALL) : 0)).fill({});
    setCallData(data.call_data.slice(0, constClass.MAX_CALL).concat(check_blank));
    
    // 不在(ABS)
    check_blank = new Array(constClass.MAX_ABS - (data.abs_data ? Math.min(data.abs_data.length, constClass.MAX_ABS) : 0)).fill({});
    setAbsData(data.abs_data.slice(0, constClass.MAX_ABS).concat(check_blank));

    previos_call_data = data.call_data.map(d => d.receipt_num);
  }

  // 半角英数字を全角英数字に変換する
  const hankaku2Zenkaku = (str) => {
    return str.replace(/[A-Za-z0-9]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
  }

  const getDisplayName = (language, name) => {
    if (language.includes('ja')) {
      return constClass.JA_DISPLAY_NAME[name];
    } else if (language.includes('en')) {
      return constClass.EN_DISPLAY_NAME[name];
    } else if (language === 'zh-CN' || language === 'zh-Hans') {
      return constClass.ZHHANS_DISPLAY_NAME[name];
    } else if (language === 'zh-TW' || language === 'zh-Hant' || language === 'zh-HK') {
      return constClass.ZHHANT_DISPLAY_NAME[name];
    } else if (language.includes('ko')) {
      return constClass.KO_DISPLAY_NAME[name];
    } else {
      return constClass.EN_DISPLAY_NAME[name];
    }
  };

  const setNextLanguage = useCallback(() => {
    if (language === 'en') {
      setLanguage('zh-TW');
    } else if (language === 'zh-TW') {
      setLanguage('zh-CN');
    } else if (language === 'zh-CN') {
      setLanguage('ko');
    } else if (language === 'ko') {
      setLanguage('en');
    }
  }, [language]);

  useEffect(() => {
    var intervalId2;
    intervalId2 = setInterval(() => {
      clearInterval(intervalId2);
      setNextLanguage();
    }, 5000);
  }, [language]);

  useEffect(() => {
    var intervalId;
    function fetchData() {
      refreshOrderCount();
      intervalId = setInterval(() => {
        refreshOrderCount();
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
    return fetchData();
  }, []);

  return (
    <div className="container-fluid text-center">
      <div className="row px-2 pb-1 pt-2">
        {wait_count !== null && (
          <div className="col-12">
            <h1 className="waiting">現在     {hankaku2Zenkaku(count_order)}     組待ち</h1>
            <h3 className="waiting">{getDisplayName(language, 'SIGNAGE_TOP_1')} {hankaku2Zenkaku(count_order)} {getDisplayName(language, 'SIGNAGE_TOP_2')}</h3>
          </div>
        )}
      </div>
      <div className="row px-2 py-0">
        <div className="d-none">{util.inspect(call_data)}</div>
        <div className="col-12">
          <table className="table table-call mb-1">
            <thead className="bg-call-head">
              <tr>
                <td colSpan="5">
                  <h1 className="mb-0 call-head">お呼出中の整理券番号</h1>
                  <h3 className="mb-0 call-head">{getDisplayName(language, 'SIGNAGE_CALL')}</h3>
                </td>
              </tr>
            </thead>
            <tbody className="bg-white">
              {call_data !== null && call_data.map((data, idx) => (
                idx % 5 === 0 ? (
                  <tr key={idx}>
                    <td className="w-20"><h1 className={`mb-0 call ${highlight.includes(data.receipt_num) ? "blink" : ""}`}>{data.receipt_num ? hankaku2Zenkaku(data.receipt_num) : '-'}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 call ${highlight.includes(call_data[idx + 1].receipt_num) ? "blink" : ""}`}>{call_data[idx + 1].receipt_num ? hankaku2Zenkaku(call_data[idx + 1].receipt_num) : '-'}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 call ${highlight.includes(call_data[idx + 2].receipt_num) ? "blink" : ""}`}>{call_data[idx + 2].receipt_num ? hankaku2Zenkaku(call_data[idx + 2].receipt_num) : '-'}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 call ${highlight.includes(call_data[idx + 3].receipt_num) ? "blink" : ""}`}>{call_data[idx + 3].receipt_num ? hankaku2Zenkaku(call_data[idx + 3].receipt_num) : '-'}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 call ${highlight.includes(call_data[idx + 4].receipt_num) ? "blink" : ""}`}>{call_data[idx + 4].receipt_num ? hankaku2Zenkaku(call_data[idx + 4].receipt_num) : '-'}</h1></td>
                  </tr>
                ) : null
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row px-2 py-0">
        <div className="col-12">
          <table className="table table-absence mb-1">
            <thead className="bg-absence-head">
              <tr>
                <td colSpan="5">
                  <h1 className="mb-0 absence-head">お呼び出し済みでご不在のお客様</h1>
                  <h3 className="mb-0 absence-head">{getDisplayName(language, 'SIGNAGE_ABS')}</h3>
                </td>
              </tr>
            </thead>
            <tbody className="bg-absence">
              {abs_data !== null && abs_data.map((data, idx) => (
                idx % 5 === 0 ? (
                  <tr key={idx}>
                    <td className="w-20"><h1 className={`mb-0 absence`}>{data.receipt_num ? hankaku2Zenkaku(data.receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence`}>{abs_data[idx + 1].receipt_num ? hankaku2Zenkaku(abs_data[idx + 1].receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence`}>{abs_data[idx + 2].receipt_num ? hankaku2Zenkaku(abs_data[idx + 2].receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence`}>{abs_data[idx + 3].receipt_num ? hankaku2Zenkaku(abs_data[idx + 3].receipt_num) : "-"}</h1></td>
                    <td className="w-20"><h1 className={`mb-0 absence`}>{abs_data[idx + 4].receipt_num ? hankaku2Zenkaku(abs_data[idx + 4].receipt_num) : "-"}</h1></td>
                  </tr>
                ) : null
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row px-2 pt-1">
        <div className="col text-danger">
          <h2 className="message">上記整理券をお持ちのお客様は店舗スタッフにお声がけください</h2>
          <h3 className="message">{getDisplayName(language, 'SIGNAGE_BOTTOM_1')}{getDisplayName(language, 'SIGNAGE_BOTTOM_2')}</h3>
        </div>
      </div>
    </div>
  )
}

export default Signage;